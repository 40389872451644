.dropZone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 2px dashed var(--theme-color-gray-6);
  user-select: none;
}

.dropZoneNoFocus {
  outline: 0;
}

.dropZoneFocus {
  outline:  2px solid var(--theme-color-3);
}

.dropZoneError {
  border: 2px dashed var(--theme-color-error);
}

.dropZoneDisabled {
  background-color: var(--theme-color-gray-1);;
}

.iconClose {
  font-size: 1.5rem;
  color: var(--theme-color-1);
}

.imagePreview:focus {
  outline: 2px solid var(--theme-color-3);
}

.imagePreviewSelected {
  border: 3px solid var(--theme-color-1);
}
