.container {
  width: 100%;
}
.container > textarea::placeholder {
  color: var(--theme-color-gray-4);
  opacity: 1;
}

.autoGrowOff {
  display: flex;
}
.autoGrowOff > textarea {
  width: 100%;
  outline: none;
  border: 2px solid var(--theme-color-gray-6);
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.autoGrowOn {
  display: grid;
}

.autoGrowOn::after {
  /* Prevent jumpy behavior. */
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.autoGrowOn > textarea {
  resize: none;
  overflow: hidden;
}
.autoGrowOn > textarea, .autoGrowOn::after {
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;

  box-sizing: border-box;
  width: 100%;
  outline: none;
  border: 2px solid var(--theme-color-gray-6);
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.inputError {
  border: 2px solid var(--theme-color-error) !important;
}

.input:focus {
  outline:  2px solid var(--theme-color-3);
}

.input:disabled {
  background-color: var(--theme-color-gray-1);;
}
