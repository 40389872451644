.container {
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  padding-top: 2rem;
  padding-bottom: 2rem;

  /* Firefox - Remove scrollbars. */
  scrollbar-width: none;
}

/* Chrome - Remove scrollbars. */
.container::-webkit-scrollbar {
  display: none;
}

.recipeEntry {
  position: relative;;
  display: flex;
  flex: none;
  width: 18.75rem;
  height: 12.5rem;
  transition: all 0.2s ease-in-out;
  -moz-user-select: none;
  user-select: none;
}

.recipeEntryImage {
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  pointer-events: none;
}

.recipeEntryBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 3rem;
  width: 100%;
  bottom: 0.5rem;
  background-color: var(--theme-color-gray-0);
  opacity: 0.70;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.recipeEntry:hover {
  z-index: 1;
  transform: scale(1.2);
}

/* Translate away from edge of container */
.recipeEntryFirst:hover {
  transform: scale(1.2) translate3d(1.6rem, 0px, 0px);
}
.recipeEntryLast:hover {
  transform: scale(1.2) translate3d(-1.6rem, 0px, 0px);
}
