@font-face {
  font-family: 'Outfit';
  src: local('Outfit'), url(./component/display/font/Outfit/Outfit-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoMono';
  src: local('RobotoMono'), url(./component/display/font/Roboto_Mono/RobotoMono-VariableFont_wght.ttf) format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.0;
  /*
    10px = 0.625rem
    12px = 0.75rem
    14px = 0.875rem
    16px = 1rem
    18px = 1.125rem
    20px = 1.25rem
    24px = 1.5rem
    30px = 1.875rem
    32px = 2rem
   */
}

html, body, #root {
  height: 100%;
}
