.input[class*="-container"] {
  width: 100%;
}

.input[class*="-container"] > [class*="-control"] {
  width: 100%;
  box-sizing: border-box;
  width: 100%;
  min-height: 1rem !important;
  font-size: 1rem;
  outline: none;
  appearance: none;
  border: 2px solid var(--theme-color-gray-6);
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: var(--theme-color-gray-0);
  white-space: nowrap;
}

/*
 * NOTE: Be carful updating these styles.
 *       When compiled down certain css names are *not* available.
 *       Hence why :nth-child is used a lot.
 */
.input[class*="-container"] > [class*="-control"] > :nth-child(1) {
  margin: 0;
  padding: 0;
  height: 1rem;
  min-height: 1rem !important;
}
.input[class*="-container"] > [class*="-control"] > :nth-child(1) > :nth-child(1) {
  position: absolute;
}
.input[class*="-container"] > [class*="-control"] > :nth-child(1) > :nth-child(2) {
  margin: 0;
  padding: 0;
  height: 1rem;
  min-height: 1rem !important;
}
.input[class*="-container"] > [class*="-control"] > :nth-child(2) > :nth-child(2) {
  padding: 0;
  height: 1rem;
  min-height: 1rem !important;
}
