.theme {
  height: 100%;
}

.themeLightGreen {
  --theme-color-1: #2BBB96;
  --theme-color-2: #35BDA6;
  --theme-color-3: #89E4D5;
  --theme-color-4: #89F4F5;
  --theme-color-error: #FF0000;

  --theme-color-gray-0: #FFFFFF;
  --theme-color-gray-1: #F8F8F8;
  --theme-color-gray-2: #EEEEEE;
  --theme-color-gray-3: #CCCCCC;
  --theme-color-gray-4: #BBBBBB;
  --theme-color-gray-5: #333333;
  --theme-color-gray-6: #000000;

  font-family: Outfit, Arial, serif;
}

.content {
  /* Set content area to this class */
  height: 100%;
  overflow: auto;
  background-color: var(--theme-color-gray-0);
  font-size: 14px;
}

