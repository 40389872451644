.container {
  display: flex;
  width: 100%;
}

.input {
  box-sizing: content-box;
  width: 100%;
  height: 1rem;
  font-size: 1rem;
  outline: none;
  border: 2px solid var(--theme-color-gray-6);
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: var(--theme-color-gray-0);
}

.input::placeholder {
  color: var(--theme-color-gray-4);
  opacity: 1;
}

.inputError {
  border: 2px solid var(--theme-color-error);
}

.input:disabled {
  background-color: var(--theme-color-gray-1);;
}

.input:focus {
  outline:  2px solid var(--theme-color-3);
}
