.button {
  display: inline-flex;
  padding: 0.75rem;
  text-align:center;
  text-decoration: none;
  font-size: 1rem;
  white-space: nowrap;
  outline: 0;
  border: none;
  border-radius: .5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button:disabled {
  background-color: var(--theme-color-gray-1);
  border: 1px solid var(--theme-color-gray-6);
  color: var(--theme-color-gray-4);
}

.outline {
  background-color: unset;
  color: var(--theme-color-gray-6);
  border: 1px solid var(--theme-color-gray-6);
}
.outline:hover {
  background-color: var(--theme-color-gray-2);
}
.outline:focus {
  outline: 2px solid var(--theme-color-3);
}

.fill {
  background-color: var(--theme-color-1);
  color: var(--theme-color-gray-0);
}
.fill:hover {
  background-color: var(--theme-color-3);
}
.fill:focus {
  outline: 2px solid var(--theme-color-3);
}
