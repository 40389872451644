.typography {
  font-family: Outfit, Arial, serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.0;
}

.titleXl {
  font-size: 3rem;
  font-weight: 500;
}
.titleLg {
  font-size: 2rem;
  font-weight: 500;
}
.title {
  font-size: 1.5rem;
  font-weight: 400;
}
.caption {
  font-family: RobotoMono, monospace, serif;
  font-size: 0.875rem;
  font-weight: 400;
}
.body {
  font-size: 1rem;
  font-weight: 400;
}
