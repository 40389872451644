.content {
  width: auto;
  margin-bottom: 6rem;
}

.screenSizeXs {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.screenSizeSm {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}

.screenSizeMd {
  margin-left: 1rem;
  margin-right: 1rem;
}

.screenSizeLg {
  margin-left: 1.125rem;
  margin-right: 1.125rem;
}

.screenSizeXl {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
