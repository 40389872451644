.content {
  display: flex;
  flex-direction: column;
}

.screenSizeXs {
  margin-left: 1rem;
  margin-right: 1rem;
}

.screenSizeSm {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.screenSizeMd {
  margin-left: 4rem;
  margin-right: 4rem;
}

.screenSizeLg {
  margin-left: 6.5rem;
  margin-right: 6.5rem;
}

.screenSizeXl {
  margin-left: 10rem;
  margin-right: 10rem;
}
